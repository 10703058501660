import React from 'react';
import {ChoiceList, Stack, Subheading} from '@shopify/polaris';
import update from 'immutability-helper';
import styled from "styled-components";

const CheckoutOfferTypeWrapper = styled.div`
    padding-top: 1.6rem;
    .rule-type-subtitle {
        margin: 0px 0 20px;
    }
    .rule-type-option {
        position: relative;
        border: 2px solid #AAA;
        border-radius: 4px;
        padding: 10px 10px;
        margin-bottom: 25px;
        .rule-type-image {
            text-align: center;
            img {
                width: 85px;
            }
        }
        .rule-type-image:nth-of-type(2) {
            img {
                width: 96px;
            }
        }
        .rule-type-desp {
            max-width: 210px;
        }
    }
    .active {
        border: 2px solid #008060;
    }
`

const OfferTypeSelector = ({checkoutOfferType, setCheckoutOfferType, state, setState, existingOffer}) => {

  const handleCheckoutOfferTypeChange = (_checked, newValue) => {
    const variantsTriggers = {
      if:{
        all:[
          {fact:'cartProducts',operator: 'hasAny',value: null },
          {fact:'cartProducts',operator: 'Variant', value: null}
        ]
      },
      then: {
        all:[
          {fact:'upsellVariant',operator: 'Variant',value: null}
        ]
      },
      swap: false,
      type: newValue
    }

    setCheckoutOfferType(newValue)
    if("manual" !== newValue){
      if(existingOffer && existingOffer.shopBrain){
        if(existingOffer.variantsTriggers && ["ai", "autopilot"].includes(existingOffer.variantsTriggers.type)){
          const inclusion = [{setting: newValue === 'autopilot' ? 'collection' : 'tags', value: null}];
          const exclusion = [{setting: 'tags', value: null}];

          setState(update(state, { shopBrain: { $set: { inclusion, exclusion } }, variantsTriggers:{$set: {
                ...existingOffer.variantsTriggers,
                type: newValue
              }}}))
        }else{
          setState(update(state, { shopBrain: { $set: existingOffer.shopBrain }, variantsTriggers:{$set:variantsTriggers}}))
        }
      }else{
        const inclusion = [{setting: newValue === 'autopilot' ? 'collection' : 'tags', value: null}];
        const exclusion = [{setting: 'tags', value: null}];

        if(existingOffer && existingOffer.variantsTriggers && ["ai", "autopilot"].includes(existingOffer.variantsTriggers.type)){
          setState(update(state, { shopBrain: { $set: { inclusion, exclusion} }, variantsTriggers:{$set:existingOffer.variantsTriggers}}))
        }else{
          setState(update(state, { shopBrain: { $set: { inclusion, exclusion} }, variantsTriggers:{$set:variantsTriggers}}))
        }
      }
    }else{
      if(existingOffer && existingOffer.variantsTriggers && "manual" === existingOffer.variantsTriggers.type){
        setState(update(state, { shopBrain: { $set: null }, variantsTriggers:{$set:existingOffer.variantsTriggers} }))
      }else{
        if(Array.isArray(state.product) && state.product.length > 0 && state.product[0]?.product?.options){
          setState(update(state, { shopBrain: { $set: null }, variantsTriggers:{$set:variantsTriggers} }))
        }else{
          setState(update(state, { shopBrain: { $set: null }}))
          if (existingOffer?.offerType === 'Post Purchase') {
            setState(update(state, { shopBrain: { $set: null }, variantsTriggers:{$set:variantsTriggers} }))
          }
        }
      }
    }
  }

  return (
    <CheckoutOfferTypeWrapper>
      <Stack>
        <Subheading>Product selection</Subheading>
        <ChoiceList
          title="Product selection<"
          titleHidden
          choices={[
            {
              label: 'Manual',
              value: 'manual',
              helpText: 'Manually choose the products to show as an upsell',
            },
            {
              label: 'ShopBrain',
              value: 'ai',
              helpText: 'Let our algorithm show the best product recommendations',
            },
            {
              label: 'Autopilot',
              value: 'autopilot',
              helpText: 'Show random items from a collection or with a certain tag',
            },
          ]}
          selected={checkoutOfferType}
          onChange={(value) => handleCheckoutOfferTypeChange(null, value[0])}
        />
      </Stack>
    </CheckoutOfferTypeWrapper>
  );
};

export default OfferTypeSelector;